import MainLayout from './containers/MainLayout';

function App() {

  return (
    <MainLayout />
  );
}

export default App;
