import styled from '@emotion/styled';
import moment from 'moment'
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { IApiEvent, IApiLang, IApiTranslate } from '../models/apiModel';
import { languageSelector } from '../redux/languagesSlice';
import { Button, NumInput, TextInput } from './shared-components';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-decoration: none;
`

const DetailsContainer = styled.div`
  width: 80%;
  padding: 0 20px;
  margin-bottom: 20px;
`

const ItemsHeader = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  opacity: 0.8;
  flex: 1;
`

const LineContainer = styled.div<{ bottom?: number }>`
  display: flex;
  margin-bottom: ${({ bottom }) => bottom ?? 10}px;
  align-items: center;
  font-size: 18px;
`

const LangLabel = styled.div`
  width: 40px;
`

const NameInput = styled(TextInput)`
  margin: 0 10px 0 0;
`

interface IProps {
  data: IApiEvent
  onEdit: (event: IApiEvent) => void
}

interface ILangItem extends IApiLang {
  translate?: IApiTranslate
  eventId: number
}

const EventDetailsComponent = ({ data, onEdit }: IProps) => {
  console.log('[EventDetailsComponent data]', data)
  const languages = useSelector(languageSelector)

  const [name, setName] = useState('')
  const [day, setDay] = useState(1)
  const [month, setMonth] = useState(1)
  // const [translates, setTranslates] = useState<ITranslate[]>([])
  const [langItems, setLangItems] = useState<ILangItem[]>([])

  const changeEvent = () => {
    const translates: any[] = []
    langItems.forEach((l) => {
      if (l.translate) translates.push(l.translate)
    })
    const date = new Date(Date.UTC(2000,month - 1, day))
    onEdit({
      id: data.id,
      name: name,
      date: moment(date).format('YYYY-MM-DD'),
      translates: translates
    })
  }
  
  const setTranslate = (text: string, langId: number, ) => {
    const index = langItems.findIndex((l) => l.id === langId)
    if (index === -1 || !langItems[index]) return

    const newLangItems = [...langItems]
    const newItem = newLangItems[index]

    newItem.translate = {
      id: newItem.translate?.id ?? -1,
      languageId: langId,
      eventId: newItem.eventId,
      text: text
    }
    newLangItems[index] = newItem
    setLangItems(newLangItems)
  }

  useEffect(() => {
    if (data) {
      // const date = new Date(data.date)
      const date = moment(data.date, 'YYYY-MM-DD').toDate()
      console.log('[date]', date)
      setName(data.name)
      setDay(date.getDate())
      setMonth(date.getMonth() + 1)
    }
  }, [data])

  useEffect(() => {
    if (languages && data.translates) {
      const newLangItems: ILangItem[] = []
      languages.forEach((l: any) => newLangItems.push({
        id: l.id,
        name: l.name,
        translate: data.translates?.find((t) => t.languageId === l.id) ?? { text: '', languageId: l.id, eventId: data.id, id: -1 },
        eventId: data.id
      }))
      setLangItems(newLangItems)
      return () => setLangItems([])
    }
  }, [languages, data])

  return (
    <Layout>
      <DetailsContainer>
        <ItemsHeader>Детали</ItemsHeader>
        <LineContainer bottom={30}>
          <NameInput value={name} placeholder="Название" onChange={(ev) => setName(ev.target.value)}/>
          Дата (дд/мм):
          <NumInput value={day} onChange={(ev) => setDay(Number.parseInt(ev.target.value))} placeholder="ДД" type="number" min={1} max={31}/>
          <NumInput value={month} onChange={(ev) => setMonth(Number.parseInt(ev.target.value))} placeholder="ММ" type="number" min={1} max={12}/>
        </LineContainer>
        <ItemsHeader>Локализация</ItemsHeader>
        {langItems.map((lang) => (
          <LineContainer key={lang.id}>
            <LangLabel>{lang.name}</LangLabel>
            <NameInput value={lang.translate?.text} onChange={(ev) => setTranslate(ev.target.value, lang.id)}/>
          </LineContainer>
        ))}
        <Button onClick={changeEvent}>Изменить</Button>
      </DetailsContainer>
    </Layout>
  );
}

export default EventDetailsComponent
