import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux'
import { IApiEvent } from '../models/apiModel';
import { IEvent } from '../models/models';
import { setLanguagesAction } from '../redux/languagesSlice';
import * as apiService from '../services/ApiService'
import AddEventPopup from './AddEventPopup';
import EventDetailsComponent from './EventDetailsComponent';
import { Button } from './shared-components';

const Layout = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  text-decoration: none;
`

const ItemsBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 20%;
  height: 100%;
  border-right: solid 1px gray;
  margin-bottom: 20px;
`

const ItemsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  margin: 0 auto 0 20px;
`

const DetailsContainer = styled.div`
  width: 80%;
  padding: 0 20px;
  margin-bottom: 20px;
`

const LineContainer = styled.div`
  display: flex;
  padding: 0 20px;
`

const ItemsHeader = styled.div`
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 20px;
  opacity: 0.8;
  flex: 1;
`

const LinkLabel = styled.div<{ selected?: boolean }>`
  font-size: 14px;
  margin: 8px 0;
  cursor: pointer;
  font-weight: ${({ selected }) => (selected ? 700 : 500)};
  &:hover {
    text-decoration: underline;
  }
  text-decoration: ${({ selected }) => (selected ? 'underline' : 'none')};
`

const ManagerComponent = () => {
  const dispatch = useDispatch()

  const [events, setEvents] = useState<IApiEvent[]>([])
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const [isAddEvent, setIsAddEvent] = useState(false)

  const addEvent = (event: IEvent) => {
    apiService.addEvent(event)
    .then((res) => {
      setIsAddEvent(false)
      loadEvents()
    })
  }

  const editEvent = (event: IApiEvent) => {
    apiService.editEvent(event)
    .then((res) => {
      setIsAddEvent(false)
      setSelectedEvent(null)
      loadEvents()
    })
  }

  const loadEvents = () => {
    apiService.getEvents()
    .then((res) => {
      setEvents(res)
    })
  }

  const loadLang = () => {
    apiService.getLanguages()
    .then((res) => {
      dispatch(setLanguagesAction(res))
    })
  }

  useEffect(() => {
    loadEvents()
    loadLang()
  }, [])

  return (
    <Layout>
      <ItemsBlock>
        <LineContainer>
          <ItemsHeader>Поводы</ItemsHeader>
          <Button onClick={() => setIsAddEvent(true)}>Добавить</Button>
        </LineContainer>
        <ItemsContainer>
          {events.map((ev) => (
            <LinkLabel key={ev.id} onClick={() => setSelectedEvent(ev)} selected={selectedEvent?.id === ev.id}>{ev.name}</LinkLabel>
          ))}
        </ItemsContainer>
      </ItemsBlock>
      <DetailsContainer>
        {selectedEvent && (
          <EventDetailsComponent data={selectedEvent} onEdit={editEvent}/>
        )}
      </DetailsContainer>

      {isAddEvent && (
        <AddEventPopup 
          onCancel={() => setIsAddEvent(false)}
          onAdd={addEvent}
        />
      )}
    </Layout>
  );
}

export default ManagerComponent
