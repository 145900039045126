import styled from "@emotion/styled";

export const Button = styled.div<{ disabled?: boolean }>`
  padding: 9px;
  width: fit-content;
  border-radius: 10px;
  font-size: 14px;
  background: gray;
  height: fit-content;
  color: white;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
  &:active {
    opacity: 0.5;
  }
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  pointer-events: ${({ disabled }) => (disabled ? 'none' : 'all')};
`

export const TextInput = styled.input`
  font-size: 16px;
  margin-bottom: 15px;
  padding: 4px;
  width: 300px;
`

export const NumInput = styled(TextInput)`
  width: 50px;
  margin: 0 10px;
`