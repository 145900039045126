import { createSlice } from '@reduxjs/toolkit'

export const languagesSlice = createSlice({
    name: 'languages',
    initialState: {
      data: [],
    },
    reducers: {
        setLanguagesAction: (state, action) => {
        console.log('[setLanguagesAction]', state.data, action)
        state.data = action.payload
      },
    },
  })
  
// Action creators are generated for each case reducer function
export const { setLanguagesAction } = languagesSlice.actions
export const languageSelector = (state: any) => state.languages.data

export default languagesSlice.reducer