import styled from '@emotion/styled';
import ManagerComponent from '../components/ManagerComponent';

const RootLayout = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
`

const Header = styled.div`
  font-size: 35px;
  font-weight: 700;
  // text-decoration: underline;
  margin: 20px 0 40px 20px;
  opacity: 0.8;
`

const Label = styled.span`
  font-size: 15px;
  font-weight: 500;
`

const Content = styled.div`
  height: calc(100% - 103px);
`

const MainLayout = () => {

  return (
    <RootLayout>
      <Header>DrinkReason <Label>aдминка</Label></Header>
      <Content>
        <ManagerComponent />
      </Content>
    </RootLayout>
  );
}

export default MainLayout
