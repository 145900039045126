import axios from 'axios';
import moment from 'moment';
import { IApiEvent } from '../models/apiModel';
import { IEvent } from '../models/models';

const ManagerHeaders = {
  'role': 'godofdrink'
}

const rootUrl = 'https://drink-reason-api.herokuapp.com'

export const getEvents = (): Promise<any[]> => {
    return new Promise((res, err) => {
        axios
        .get(`${rootUrl}/v1/events`,
        {
            headers: {
              'Cache-Control': 'no-cache',
              'Pragma': 'no-cache',
              'Expires': '0',
            },
        })
        .then((data) => {
            console.log('[api][getEvents][OK]', data.data)
            res(data.data)
        })
        .catch((error) => {
            err(error)
        })
    })
}

export const addEvent = (event: IEvent): Promise<any> => {
    return new Promise((res, err) => {
        const date = new Date(Date.UTC(2000,event.month - 1, event.day))
        const dateString = moment(date).format('YYYY-MM-DD')
        axios
        .post(`${rootUrl}/v1/events`,{
            name: event.name,
            date: dateString,
            translates: event.translates
        }, {
            headers: ManagerHeaders
        })
        .then((data) => {
            console.log('[api][addEvent][OK]', data.data)
            res(data.data)
        })
        .catch((error) => {
            console.log('[api][addEvent][Error]', error)
            err(error)
        })
    })
}

export const getLanguages = (): Promise<any[]> => {
    return new Promise((res, err) => {
        axios
        .get(`${rootUrl}/v1/lang`)
        .then((data) => {
            res(data.data)
        })
        .catch((error) => {
            err(error)
        })
    })
}

export const editEvent = (event: IApiEvent): Promise<any> => {
    return new Promise((res, err) => {
        axios
        .patch(`${rootUrl}/v1/events`,{
            id: event.id,
            name: event.name,
            date: event.date,
            translates: event.translates
        }, {
            headers: ManagerHeaders
        })
        .then((data) => {
            console.log('[api][editEvent][OK]', data)
            res(data.data)
        })
        .catch((error) => {
            err(error)
        })
    })
}