import styled from '@emotion/styled';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { IEvent } from '../models/models';
import { languageSelector } from '../redux/languagesSlice';
import { Button, NumInput, TextInput } from './shared-components';

const Layout = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5);
  display: flex;
  align-items: center;
  justify-content: center;
`

const ContentContainer = styled.div`
  padding: 15px;
  background: white;
  border-radius: 10px;
`

const Header = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 20px;
`

const LineContainer = styled.div<{ bottom?: number }>`
  display: flex;
  margin-bottom: ${({ bottom }) => bottom ?? 0}px;
  align-items: center;
  font-size: 18px;
`

const ActionButton = styled(Button)`
  margin-right: 10px;
`

const CheckLabel = styled.div`
  font-size: 12px;
  margin-top: -10px;
  margin-bottom: 15px;
  > input {
    margin-right: 10px;
  }
`

interface IProps {
  onCancel: () => void
  onAdd: (event: IEvent) => void
}

const AddEventPopup = ({onCancel, onAdd}: IProps) => {
  const languages = useSelector(languageSelector)
  console.log('[languages]', languages)

  const [name, setName] = useState('')
  const [day, setDay] = useState(1)
  const [month, setMonth] = useState(1)
  const [useNameAsRuLocale, setUseNameAsRuLocale] = useState(true)

  const addEvent = () => {
    console.log('[addEvent languages]', languages)
    const ruLang = languages.find((l: any) => l.name === 'ru')
    onAdd({
      name,
      day: day,
      month: month,
      translates: useNameAsRuLocale ? [
        {
          id: -1,
          text: name,
          languageId: ruLang.id,
          eventId: -1
        }] : [],
    })
  }

  const canAdd = name !== ''

  return (
    <Layout>
      <ContentContainer>
        <Header>Новый повод</Header>
        <TextInput placeholder="Название" onChange={(ev) => setName(ev.target.value)}/>
        <CheckLabel><input type="checkbox" checked={useNameAsRuLocale} onChange={() => setUseNameAsRuLocale((v) => !v)} />Использовать название как локализацию</CheckLabel>
        <LineContainer bottom={15}>
          Дата (дд/мм):
          <NumInput value={day} onChange={(ev) => setDay(Number.parseInt(ev.target.value))} placeholder="ДД" type="number" min={1} max={31}/>
          <NumInput value={month} onChange={(ev) => setMonth(Number.parseInt(ev.target.value))} placeholder="ММ" type="number" min={1} max={12}/>
        </LineContainer>
        <LineContainer>
          <ActionButton disabled={!canAdd} onClick={addEvent}>Добавить</ActionButton>
          <ActionButton onClick={onCancel}>Отмена</ActionButton>
        </LineContainer>
      </ContentContainer>
    </Layout>
  );
}

export default AddEventPopup
